export default defineNuxtPlugin(() => {
  const { GOOGLE_ANALYTICS_TAG_ID, NODE_ENV } = useRuntimeConfig().public;

  if (NODE_ENV === 'development') {
    return;
  }
  function gtag() {
    window.dataLayer.push(arguments);
  }

  window.dataLayer = window.dataLayer || [];

  gtag(`js`, new Date());
  gtag(`config`, GOOGLE_ANALYTICS_TAG_ID);

  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_TAG_ID}`,
        async: true,
      },
    ],
  });
});
