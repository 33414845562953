import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
// import { aliases as allAliases, mdi } from 'vuetify/iconsets/mdi-svg';

// const aliases = {
//   /* Only used icon aliases here */
//   close: allAliases.close,
//   menu: allAliases.menu,
//   menuDown: allAliases.menuDown,
// };

const commonColors = {
  primary: `#2DD4BF`,
  'primary-darken-1': '#008C76',
  'primary-darken-2': `#018786`,
  secondary: `#7F56D9`,
  error: `#B00020`,
  info: `#2196F3`,
  'info-darken-1': '#0468c8',
  'info-darken-2': `#1F5592`,
  success: `#4CAF50`,
  warning: `#FB8C00`,
};

const customDark = {
  dark: true,
  colors: {
    ...commonColors,
    background: `#000`,
    color: `#fff`,
  },
  variables: {
    // #7F56D9
    // --indigo-400: #5e5def;
    // --indigo-500: #4e4ec7;
    // --indigo-600: #3f3e9f;
    // --indigo-700: #2f2f78;
    // --indigo-800: #1f1f50;
  },
};

const customLight = {
  dark: false,
  colors: {
    ...commonColors,
    background: `#fff`,
    color: `#000`,
  },
};

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    // ... your configuration
    // Vuetify module configuration: https://go.nuxtjs.dev/config-vuetify
    theme: {
      defaultTheme: `customLight`,
      themes: {
        customDark,
        customLight,
      },
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
  });

  app.vueApp.use(vuetify);
});
