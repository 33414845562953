async function lazyLoadSentryIntegrations(addIntegration) {
  const { Replay } = await import('@sentry/replay');
  addIntegration(
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  );
}

async function initializeSentry(nuxtApp, config) {
  const {
    init: sentryInit,
    browserTracingIntegration,
    addIntegration,
  } = await import('@sentry/vue');

  const router = useRouter();
  const browserTracing = new browserTracingIntegration({
    routingInstrumentation: browserTracingIntegration(router),
  });

  sentryInit({
    app: nuxtApp.vueApp,
    dsn: config.public.SENTRY_DSN_PUBLIC || null,
    integrations: [browserTracing],
    tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE,
    tracePropagationTargets: [/^https:\/\/users-driven\.com/],
    replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE,
    replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE,
  });

  lazyLoadSentryIntegrations(addIntegration);
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const config = useRuntimeConfig();

    if (!import.meta.client || config.public.NODE_ENV === 'development') {
      return;
    }

    // Defer Sentry initialization to avoid blocking the main thread
    setTimeout(() => {
      initializeSentry(nuxtApp, config);
    }, 0);
  },
});
