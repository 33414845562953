import { default as _91_46_46_46slug_93QvcqcC3xvKMeta } from "/vercel/path0/pages/blog/[...slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as checkoutlZQLvi17ODMeta } from "/vercel/path0/pages/checkout.vue?macro=true";
import { default as companiesd8HmMpceeyMeta } from "/vercel/path0/pages/companies.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as give_45feedbackxVgC1K5QGtMeta } from "/vercel/path0/pages/give-feedback.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as terms_45of_45serviceSmXvkgSRNCMeta } from "/vercel/path0/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93QvcqcC3xvKMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93QvcqcC3xvKMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93QvcqcC3xvKMeta || {},
    alias: _91_46_46_46slug_93QvcqcC3xvKMeta?.alias || [],
    redirect: _91_46_46_46slug_93QvcqcC3xvKMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexmfxAx2eWMBMeta?.name ?? "blog",
    path: indexmfxAx2eWMBMeta?.path ?? "/blog",
    meta: indexmfxAx2eWMBMeta || {},
    alias: indexmfxAx2eWMBMeta?.alias || [],
    redirect: indexmfxAx2eWMBMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutlZQLvi17ODMeta?.name ?? "checkout",
    path: checkoutlZQLvi17ODMeta?.path ?? "/checkout",
    meta: checkoutlZQLvi17ODMeta || {},
    alias: checkoutlZQLvi17ODMeta?.alias || [],
    redirect: checkoutlZQLvi17ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: companiesd8HmMpceeyMeta?.name ?? "companies",
    path: companiesd8HmMpceeyMeta?.path ?? "/companies",
    meta: companiesd8HmMpceeyMeta || {},
    alias: companiesd8HmMpceeyMeta?.alias || [],
    redirect: companiesd8HmMpceeyMeta?.redirect,
    component: () => import("/vercel/path0/pages/companies.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: give_45feedbackxVgC1K5QGtMeta?.name ?? "give-feedback",
    path: give_45feedbackxVgC1K5QGtMeta?.path ?? "/give-feedback",
    meta: give_45feedbackxVgC1K5QGtMeta || {},
    alias: give_45feedbackxVgC1K5QGtMeta?.alias || [],
    redirect: give_45feedbackxVgC1K5QGtMeta?.redirect,
    component: () => import("/vercel/path0/pages/give-feedback.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQl0qlywOJTMeta?.name ?? "privacy-policy",
    path: privacy_45policyQl0qlywOJTMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    alias: privacy_45policyQl0qlywOJTMeta?.alias || [],
    redirect: privacy_45policyQl0qlywOJTMeta?.redirect,
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceSmXvkgSRNCMeta?.name ?? "terms-of-service",
    path: terms_45of_45serviceSmXvkgSRNCMeta?.path ?? "/terms-of-service",
    meta: terms_45of_45serviceSmXvkgSRNCMeta || {},
    alias: terms_45of_45serviceSmXvkgSRNCMeta?.alias || [],
    redirect: terms_45of_45serviceSmXvkgSRNCMeta?.redirect,
    component: () => import("/vercel/path0/pages/terms-of-service.vue").then(m => m.default || m)
  }
]